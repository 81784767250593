<template>
    <div class="container mx-auto p-6">
      <h1 class="text-2xl font-bold mb-6">Purchase Software License</h1>
      <div class="grid grid-cols-2 gap-6">
        <!-- Basic License -->
        <div class="p-4 border rounded-lg shadow-sm">
          <h2 class="text-xl font-semibold mb-4">Basic License</h2>
          <div v-for="option in basicOptions" :key="option.duration" class="flex items-center justify-between mb-2">
            <span>{{ option.duration }} months</span>
            <span>\${{ option.price }}</span>
            <button @click="purchase('Basic', option)" class="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600">
              Buy
            </button>
          </div>
        </div>
  
        <!-- Pro License -->
        <div class="p-4 border rounded-lg shadow-sm">
          <h2 class="text-xl font-semibold mb-4">Pro License</h2>
          <div v-for="option in proOptions" :key="option.duration" class="flex items-center justify-between mb-2">
            <span>{{ option.duration }} months</span>
            <span>\${{ option.price }}</span>
            <button @click="purchase('Pro', option)" class="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600">
              Buy
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        basicOptions: [
          { duration: 1, price: 10 },
          { duration: 3, price: 25 },
          { duration: 6, price: 45 },
          { duration: 12, price: 80 },
        ],
        proOptions: [
          { duration: 1, price: 20 },
          { duration: 3, price: 50 },
          { duration: 6, price: 90 },
          { duration: 12, price: 160 },
        ],
      };
    },
    methods: {
      purchase(licenseType, option) {
        // Handle the purchase logic here
        alert(`Purchased ${licenseType} License for ${option.duration} months at $${option.price}`);
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  </style>
  